import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Menu, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { intl } from 'components/GlobalProvider';
import { ListCellText } from 'certn-ui/List';
import { fetchConsentDocuments } from 'views/manager/sharedActions/ApplicationActions';
import { getComplianceRegion } from 'base/BaseSelectors';

const ItemDownloadConsentDocs = ({ applicantId, ...rest }) => {
    const dispatch = useDispatch();
    const complianceRegion = useSelector(getComplianceRegion);
    const hideConsentDownloadButton = complianceRegion?.country !== 'US';

    const downloadConsentDocuments = async () => {
        try {
            await dispatch(fetchConsentDocuments({ applicantId }));
            message.success(
                intl.formatMessage({
                    id: '004e5.StatusDropdown.consentDocumentsDownloaded',
                    defaultMessage: 'Consent Documents downloaded.',
                })
            );
        } catch (error) {
            message.error(
                intl.formatMessage({
                    id: '004e5.StatusDropdown.consentDocumentsDownloadFailed',
                    defaultMessage: 'Failed to fetch Consent Documents.',
                })
            );
        }
    };

    if (hideConsentDownloadButton) return null;
    return (
        <Menu.Item {...rest}>
            <ListCellText clickable onClick={downloadConsentDocuments}>
                <FormattedMessage
                    id="004ed.StatusDropdown.downloadConsentDocuments"
                    defaultMessage="Download Consent Documents"
                />
            </ListCellText>
        </Menu.Item>
    );
};

ItemDownloadConsentDocs.propTypes = {
    applicantId: PropTypes.string.isRequired,
};

export default ItemDownloadConsentDocs;
